<template>
    <topBanner/>
    <!-- <projects/> -->
</template>

<script>
import topBanner from '../components/innerpagestopbanner/innerTop.vue'
// import projects from '../components/projects.vue'
export default {
    components:{
        topBanner,
        // projects
    },
    data(){
        return{}
    }
}
</script>